function Contact()
{
return(
<div><h2>Kontakt</h2>
<p>Tel.: <a href="tel:+491711626055">+49 171 1626055</a><br />
Mail: <a href="mailto:kontakt@tuc-racing.de">kontakt@tuc-racing.de</a><br />
Inet: <a href="https://www.tuc-racing.de">www.tuc-racing.de</a></p>
<iframe src="https://www.google.com/maps/d/u/0/embed?mid=1Cy8uXthy4MbreN1NSvqHXmFdMAbf3n4&ll=50.79884052734347%2C12.927050714686965&z=16" width="100%" height="480"></iframe>
</div>);}
export default Contact;
