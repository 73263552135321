import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

function SpCard(props) {
    return(
        <Col>
        <Card>
            <Card.Img variant="top" src={props.src}/>
            <Card.Body>
                <Card.Title>{props.firma}</Card.Title>
                <Card.Text>{props.text}</Card.Text>
            </Card.Body>
        </Card></Col>
    )
}

function Sponsors() {
    return(
        <div>
        <h2>Sponsoren</h2>
        <p>Rennsport ist nichts ohne Sponsoren. Durch unsere Partner und Sponsoren erhalten wir wertvolle Unterstützung, die es uns ermöglicht, unsere Visionen in die Realität umzusetzen und spannende Rennmomente zu erleben. Seien Sie ein entscheidender Teil unserer Erfolgsgeschichte und helfen Sie uns dabei, die Grenzen des Möglichen neu zu definieren. Unterstützen Sie das T.U.C. Racing Team auf unserem Weg zu Spitzenleistungen und zur Förderung der E-Mobilität. Ihr Beitrag als Sponsor ermöglicht es uns, unsere Leidenschaft für den Motorsport in die Realität umzusetzen und einzigartige Momente auf der Rennstrecke zu erleben. Gemeinsam können wir Geschichte schreiben und das Potenzial der E-Mobilität voll ausschöpfen.
</p><p>
Kontaktieren Sie uns noch heute und entdecken Sie die vielfältigen Sponsoring-Möglichkeiten und erfahren Sie, wie Ihr Unternehmen von einer Partnerschaft mit dem T.U.C. Racing Team profitieren kann.
</p>
        <h1>Uni-Partner</h1><br/>
        <Row xs={1} md={2} lg={3} className="g-4">
        <SpCard  firma="Maschinenbau" src="../img/supporter/Maschinenbau.png" />
        <SpCard  firma="Professur AWI" src="../img/supporter/awi.png" />
        <SpCard  firma="Professur ALF" src="../img/supporter/alf.png" />
		<SpCard  firma="Professur SLK" src="../img/supporter/slk.png" />
        <SpCard  firma="IWP" src="../img/supporter/iwp.png" />
        </Row><br/><br></br>
        <h1>Hauptsponsoren</h1><br/>
        <Row xs={1} md={2} lg={3} className="g-4">
        <a href="https://www.plm.automation.siemens.com/global/de/#"><SpCard  firma="Siemens" src="../img/supporter/siemens.png" /></a>
		<a href="https://www.kisico.de/"><SpCard firma="Kisico" src="../img/supporter/kisico.png"/></a>
		<a><SpCard firma="Sparkasse Chemnitz" src="../img/supporter/Sparkasse.png"/></a>
        </Row><br/><br></br>
        <h1>Sonstige Unterstützer</h1><br/>
        <Row xs={1} md={2} lg={4} className="g-4">
        <SpCard  firma="3dk.berlin" src="../img/supporter/3dkBerlin.png" />
		<SpCard firma="arc Solutions" src="../img/supporter/arcSolutions.png"/>
		<SpCard firma="ATS" src="../img/supporter/ats.png"/>
		<SpCard firma="BTR" src="../img/supporter/btr.png"/>
		<SpCard firma="Bürklin" src="../img/supporter/buerklin.png"/>
		<SpCard firma="DAVOSCAN" src="../img/supporter/davoscan.png"/>
		<SpCard firma="Dittmann Rechtsanwälte" src="../img/supporter/dittmann.png"/>
		<SpCard firma="EDAG" src="../img/supporter/edag.png"/>
		<SpCard firma="Esni" src="../img/supporter/esni.png"/>
		<SpCard firma="Eyltex" src="../img/supporter/eyltex.png"/>
		<SpCard firma="Fischer Elektronik" src="../img/supporter/fischer_elektronik.png"/>
		<SpCard firma="Ganter" src="../img/supporter/ganter.png"/>
		<SpCard firma="Gesellschaft der Freunde der TU Chemnitz e.V." src="../img/supporter/Gesellschaft_der_Freunde_der_TU.png"/>
		<SpCard firma="Harting" src="../img/supporter/harting.png"/>
		<SpCard firma="Hexagon" src="../img/supporter/Hexagon.png"/>
		<SpCard firma="ICM" src="../img/supporter/icm.png"/>
		<SpCard firma="IGUS" src="../img/supporter/igus.png"/>
		<SpCard firma="IPG" src="../img/supporter/ipg.png"/>
		<SpCard firma="Isabellenhütte" src="../img/supporter/isabellenhuette.png"/>
		<SpCard firma="Klädtke" src="../img/supporter/klaedtke.png"/>
		<SpCard firma="Marx Städter" src="../img/supporter/marxStaedter.png"/>
		<SpCard firma="maxon" src="../img/supporter/maxon.png"/>
		<SpCard firma="Merge" src="../img/supporter/merge.png"/>
		<SpCard firma="MLP" src="../img/supporter/mlp.png"/>
		<SpCard firma="Naumann Stahl" src="../img/supporter/naumannStahl.png"/>
		<SpCard firma="Rehm" src="../img/supporter/rehm.png"/>
		<SpCard firma="richter & heß" src="../img/supporter/richterHess.png"/>
		<SpCard firma="Rhode & Schwarz" src="../img/supporter/rs.png"/>
		<SpCard firma="Sachsenring" src="../img/supporter/sachsenring.png"/>
		<SpCard firma="Schloz Wöllenstein" src="../img/supporter/schlozWoellenstein.png"/>
		<SpCard firma="SMELA" src="../img/supporter/smela.png"/>
		<SpCard firma="Spedition Weise" src="../img/supporter/speditionWeise.png"/>
		{/* <SpCard firma="Steinbeis-Forschungszentrum (ALP)" src=".../img/supporter/steinbeisALP.png"/> */}
		<SpCard firma="Sternberg" src="../img/supporter/sternberg.png"/>
		<SpCard firma="Studentenwerk Chemnitz-Zwickau" src="../img/supporter/StuWe.png"/>
		<SpCard firma="Student_innenrat der TU Chemnitz" src="../img/supporter/StuRa.png"/>
		<SpCard firma="STW" src="../img/supporter/stw.png"/>
		<SpCard firma="thyssenkrupp" src="../img/supporter/thyssenkrupp.png"/>
		<SpCard firma="TrapoFit" src="../img/supporter/trapoFit.png"/>
		<SpCard  firma="Werkzeug Eylert" src="../img/supporter/eylert.png" />
		<SpCard firma="Zetron" src="../img/supporter/zetron.png"/>
		</Row>
        </div>
    )
 }

 export default Sponsors;
