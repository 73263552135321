import Thumbnail from '../comps/Thumbnail.jsx'
import Row from 'react-bootstrap/Row'

function Galerie() {
    return(
        <div>
            <h2>Die Bilder unserer Saisons</h2>
            <hr></hr>
            <br></br>

            <Row xs={1} md={2} lg={3} className='g-3'>
			<a href='/Saison_24_25'><Thumbnail name="Saison 2024/25" function="Mkx. VI" src="img/cars/hiddenCAR.jpg"></Thumbnail></a>
            <a href='/Saison_23_24'><Thumbnail name="Saison 2023/24" function="Mkx. V" src="img/cars/mkxv2.jpg"></Thumbnail></a>
            <a href='/Saison_22_23'><Thumbnail name="Saison 2022/23" function="Mkx. DV" src="img/cars/mkxdv2.jpg"></Thumbnail></a>
            <a href='/Saison_21_22'><Thumbnail name="Saison 2021/22" function="Mkx. IV" src="img/cars/mkxiv.jpg"></Thumbnail></a>
            <a href='/Saison_20_21'><Thumbnail name="Saison 19 bis 21" function="Mkx. III" src="img/cars/mkxiii2.jpg"></Thumbnail></a>
            <a href='/Saison_18_19'><Thumbnail name="Saison 2018/19" function="Mkx. II" src="img/cars/mkxii2.jpg"></Thumbnail></a>
            <a href='/Saison_17_18'><Thumbnail name="Saison 2017/18" function="Mkx. I" src="img/cars/mkxi2.jpg"></Thumbnail></a>
            
            </Row>
            


        </div>
        )
} 

export default Galerie;
